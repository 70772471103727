import {NavLink} from 'react-router-dom'
import '../../assets/css/CustomerList.css'
import Breadcrumb from "../../component/common/Breadcrumb"
function OrderList() {
  const itemList = [
    {id: 1, name: 'Coat Pant', customer: 'Kunal Signh', date:'25 Jan 2024'},
    {id: 2, name: 'Kurta Pajama', customer: 'Akram Khan', date:'10 Jan 2024'},
    {id: 3, name: 'Safari Suit', customer: 'Suraj Sharma', date:'29 Dec 2023'},
    {id: 4, name: 'Sharwani', customer: 'Haseen Ahmad', date:'24 Nov 2023'}
  ];
  const breadBtn = <>
    <button className="echron-btn-icon">
      <NavLink to="/adm/order/add" title='Add Order'><i className="fa-solid fa-plus"></i></NavLink>
    </button>
  </>
  return (
    <>
      <section className="echron-main echron-list">
        <Breadcrumb page="Order" btn={breadBtn} />
        <div className="container-fluid">
          <table className="echron-head">
            <tbody>
              <tr>
                <th align="left"><i className="fa fa-list"></i> Order List</th>
              </tr>
            </tbody>
          </table>
          <div className="echron-content content">
            <table>
              <tbody>
                <tr>
                  <th width="35">
                    <input type="checkbox" className="checkAll" />
                  </th>
                  <th>
                    <a href="#">Name</a>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </th>
                  <th><a href="#">Customer</a>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </th>
                  <th>
                    <a href="#">Date</a>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </th>
                  <th>
                    <a href="#">Action</a>
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </th>
                </tr>
                {
                  itemList.map((list, i)=>
                  <tr data-id={list.id} key={i}>
                    <th><input type="checkbox" value={list.id} /></th>
                    <td>{list.name}</td>
                    <td>{list.customer}</td>
                    <td>{list.date}</td>
                    <td className="action">
                      <button className="echron-btn-icon yellow">
                        <NavLink to="/adm/order/add" title='Edit Order'><i className="fa-solid fa-pencil"></i></NavLink>
                      </button>
                    </td>
                  </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}
export default OrderList