import {NavLink} from 'react-router-dom'
import '../../assets/css/common/Breadcrumb.css'
function Breadcrumb(props) {
  return (
    <>
      <div className="echron-breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="left">
              <h1>Admin {props.page}</h1>
              <ul>
                <li>
                  <NavLink to="/adm">
                    home
                  </NavLink>
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>{props.page}</li>
              </ul>
            </div>
            <div className="right">
              {props.btn}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Breadcrumb