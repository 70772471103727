import React, { useState, useEffect } from 'react';
import {NavLink, useSearchParams} from 'react-router-dom'
import '../../assets/css/CustomerList.css'
import Breadcrumb from "../../component/common/Breadcrumb"
import { useSelector } from 'react-redux'
import { useGetCustsQuery, usePutStatusCustsMutation } from '../../echron/clientapi'
import { selectFormResp } from "../../echron/formSlice"
import Warning from "../../component/Warning"
import { format } from 'date-fns'
import Pic from '../../component/customer/Pic';
import Tag from '../../component/customer/Tag';


function CustomerList() {
  const [echronSearchReq] = useSearchParams()
  const echronType = echronSearchReq.get("type")

  // Initializing state based on the status key
  const [echronStatus, setEchronStatus] = useState({});

  

  //api get
  const custsRes = useGetCustsQuery(echronType)
  const [custStatusPut] = usePutStatusCustsMutation()
  const items = (custsRes.isSuccess)? custsRes.data.result : []

  // Effect to initialize the state with item statuses
  useEffect(() => {
    if (custsRes.isSuccess) {
      const initialCheckedItems = items.reduce((echron, item) => {
        echron[item.id] = (item.status=='1'); // Set checked status based on status key
        return echron;
      }, {});
      setEchronStatus(initialCheckedItems);
    }
  }, [custsRes, items]);

  // Handle change when checkbox is clicked
  const echronStatusEvChange = (id, status) => {
    setEchronStatus((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
    custStatusPut({body: {status : status}, id: id})
  };

  const formResps = useSelector(selectFormResp)
  
  const breadBtn = <>
    <button className="echron-btn-icon">
      <NavLink to="/adm/customer-add" title='Add Customer'><i className="fa-solid fa-plus"></i></NavLink>
    </button>
  </>

  //success msg
  const sucesBody =
    <div className="alert alert-success"> 
      <ul>
        <li>{formResps.messageShow}</li>
      </ul>
    </div>

  const sucesHead = <h5 className="text-success">Success</h5>

  if(custsRes.isLoading) return <>Loadding...</>
  if(custsRes.isError) return <>An Error occured {custsRes.error.error}</>
  if(custsRes.isSuccess) {
    return (
      <>
        <section className="echron-main echron-list">
          <Breadcrumb page="Customer" btn={breadBtn} />
          <div className="container-fluid">
            <table className="echron-head">
              <tbody>
                <tr>
                  <th align="left"><i className="fa fa-list"></i> Customer List {echronType}</th>
                </tr>
              </tbody>
            </table>
            <div className="echron-content content">
              <table>
                <tbody>
                  <tr>
                    <th width="35">
                      <input type="checkbox" className="checkAll" />
                    </th>
                    <th>
                      <a href="#">Thumb</a>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </th>
                    <th>
                      <a href="#">Customer Details</a>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </th>
                    <th>
                      <a href="#">Magerment</a>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </th>
                    <th>
                      <a href="#">Register Date</a>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </th>
                    <th>
                      <a href="#">Action</a>
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </th>
                  </tr>
                  {
                    custsRes.data.result.map((post)=>
                    {
                      const echronDate = format(new Date(post.created_at), "dd MMM, yy");
                      const echronEmail = (post.email) && <><b>Email:</b> {post.email}</>
                      return (
                        <tr data-id={post.id} key={post.id}>
                          <th><input type="checkbox" value={post.id} /></th>
                          <td><Pic s={post.picture} /></td>
                          <td><b>Name:</b> {post.name} <br/><b>Phone:</b> {post.phone}<br/>{echronEmail}</td>
                          <td>
                            <b>(<NavLink to={"/adm/magerment/"+post.id+"/customer"} title='View Magerment' className="n-a">N/A</NavLink>)</b>
                            <button className="echron-btn-magerment">
                              <NavLink to={"/adm/magerment/"+post.id+"/customer-add"} title='Add Magerment'><i className="fa-regular fa-plus"></i></NavLink>
                            </button>
                          </td>
                          <td>{echronDate}</td>
                          <td>
                            <div className='action'>
                              <Tag t={post.type} />
                              <label title='Status Customer'>				
                                <input type="checkbox" value={post.id} checked={!!echronStatus[post.id]} onChange={() => echronStatusEvChange(post.id, post.status)} />
                                <span>
                                  <span>OFF</span>
                                  <span>ON</span>
                                </span>
                                <a></a>
                              </label>
                              <button className="echron-btn-icon yellow">
                                <NavLink to={"/adm/customer-add?id="+post.id}  title='Edit Customer'><i className="fa-solid fa-pencil"></i></NavLink>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
          {formResps.success && <Warning body={sucesBody} head={sucesHead} />}
        </section>
      </>
    )
  }
}
export default CustomerList