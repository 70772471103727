import { useState } from "react";
import '../../assets/css/common/Navbar.css'
import {NavLink, useLocation, useSearchParams} from 'react-router-dom'
function Navbar() {

  //url filter
  const echronLoc = useLocation();
  const echronLocPath = echronLoc.pathname.split('/').filter(Boolean);
  const echronLocPathLast = echronLocPath[echronLocPath.length - 1];
  const echronLocFinal = echronLocPathLast.split("-")[0];

  const [echronSearchReq] = useSearchParams();
  const echronType = echronSearchReq.get("type")
  
  //onlick dropdown
  const [isActive, setIsActive] = useState(echronLocFinal)
  const evIsActive = (li) => {
    setIsActive(li)
  }

  

  return (
    <>
      <div className="echron-navbar">
        <div className="nav-bg">
          <span><i className="fa fa-bars"></i> Navigation</span>
        </div>
        <ul className="echronEvSidebar">
          <li className={isActive === 'adm' ? 'active' : ''} onClick={() => evIsActive('adm')}>
            <a href="#">
              <span>
                <i className="fa fa-dashboard" aria-hidden="true"></i>
                Dashboard
              </span>
              <span><i className="fa fa-angle-right"></i></span>
            </a>
            <ul className="ul-menu">
              <li><NavLink to="/adm">Home</NavLink></li>
              <li><a href="#">Profile</a></li>
              <li><a href="#">Password</a></li>
              <li><a href="#">Logout</a></li>
            </ul>
          </li>
          <li className={isActive === 'customer' ? 'active' : ''} onClick={() => evIsActive('customer')}>
            <a href="#">
              <span>
                <i className="fa-solid fa-people-group"></i>
                Customer
              </span>
              <span><i className="fa fa-angle-right"></i></span>
            </a>
            <ul className="ul-menu">
              <li className={(echronType === 'all') ? 'active' : ''}><NavLink to="/adm/customer?type=all">All</NavLink></li>
              <li className={(echronType === 'vip') ? 'active' : ''}><NavLink to="/adm/customer?type=vip">VIP</NavLink></li>
              <li className={(echronType === 'prime') ? 'active' : ''}><NavLink to="/adm/customer?type=prime">Prime</NavLink></li>
              <li className={(echronType === 'normal') ? 'active' : ''}><NavLink to="/adm/customer?type=normal">Normal</NavLink></li>
            </ul>
          </li>
          <li className={isActive === 'order' ? 'active' : ''} onClick={() => evIsActive('order')}>
            <a href="#">
              <span>
                <i className="fa-solid fa-arrow-down-up-across-line"></i>
                Order
              </span>
              <span><i className="fa fa-angle-right"></i></span>
            </a>
            <ul className="ul-menu">
              <li><NavLink to="/adm/order">New</NavLink></li>
              <li><NavLink to="/adm/order">Working</NavLink></li>
              <li><NavLink to="/adm/order">Completed</NavLink></li>
              <li><NavLink to="/adm/order">Delivered</NavLink></li>
            </ul>
          </li>
        </ul>
        <div className="echron">
          <img src="https://echrontech.com/echronpanel/images/img-lib//15675670994.png" alt="Echron Tech" />
        </div>
      </div>
    </>
  );
}
export default Navbar