//router
import {BrowserRouter, Routes, Route} from 'react-router-dom'

//component
import Echrontech from './screen/Echrontech'
import Home from './screen/adm/Home'
import Login from './screen/adm/Login'
import CustomerList from './screen/adm/CustomerList'
import MagermentList from './screen/adm/MagermentList'
import CustomerAdd from './screen/adm/CustomerAdd'
import MagermentAdd from './screen/adm/MagermentAdd'
import OrderList from './screen/adm/OrderList'
import OrderAdd from './screen/adm/OrderAdd'
import HomeMain from './screen/HomeMain'
import '../src/assets/css/common/Echrontech.css'


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeMain />} />
          <Route path="/adm" element={<Echrontech />}>
            <Route index element={<Home />} />
            <Route path="/adm/customer" element={<CustomerList />} />
            <Route path="/adm/customer-add" element={<CustomerAdd />} />
            <Route path="/adm/magerment/:cust_id/customer" element={<MagermentList />} />
            <Route path="/adm/magerment/:cust_id/customer-add" element={<MagermentAdd />} />
            <Route path="/adm/order" element={<OrderList />} />
            <Route path="/adm/order/add" element={<OrderAdd />} />
          </Route>
          <Route path="/adm/login" element={<Login />} />
          <Route path="404" element={<h1>404 Error</h1>} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
