import vipTag from'../../assets/img/vip.png'
import primeTag from'../../assets/img/prime.png'

function Tag(props) {
  let tag
  const height = (props.h)? props.h : 50
  switch(props.t) {
    case '2' :
      tag = <img height={height} src={primeTag} alt="" />
    break
    case '3' :
      tag = <img height={height} src={vipTag} alt="" />
    break
    default :
      tag = ''
  }

  return tag
}
export default Tag