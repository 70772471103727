import {NavLink, useParams} from 'react-router-dom'
import { useState } from "react";
import '../../assets/css/CustomerAdd.css'
import Breadcrumb from "../../component/common/Breadcrumb"
import { useGetIdCustsQuery } from '../../echron/clientapi'
import vipTag from'../../assets/img/vip.png'
import Pic from '../../component/customer/Pic'
import Tag from '../../component/customer/Tag'
function OrderAdd() {
  const breadBtn = <>
    <button className="echron-btn-icon">
      <NavLink to="/adm/customer" title="Save"><i className="fa-solid fa-save"></i></NavLink>
    </button>
  </>

  const { cust_id } = useParams();
  //get customer api
  const custsRes = useGetIdCustsQuery(cust_id);

  let customerGet
  if(custsRes.isSuccess) {
    const custShow = custsRes.data.result
    if(custShow.length>0) {
      customerGet =
        <div className="echron-content content">
          <table>
            <tbody>
              <tr>
                <th><Pic s={custShow[0].picture} /></th>
                <th>{custShow[0].name}  <Tag t={custShow[0].type} h={35} /></th>
                <th>{custShow[0].phone}</th>
              </tr>
            </tbody>
          </table>
        </div>
    }
    else {
      window.location.href = '/adm/customer?type=all'
    }
  }
  else {
    customerGet = 'Please Wait...'
  }

  const [isActive, setIsActive] = useState('tab-1')
  const evIsActive = (tab) => {
    setIsActive(tab)
  }

  return (
    <>
      <section className="echron-main echron-addedit echron-list">
        <Breadcrumb page="Magerment Add" btn={breadBtn} />
        <div className='container-fluid'>
          <table className="echron-head">
            <tbody>
              <tr>
                <th align="left"><i className="fa fa-list"></i> Customer Details</th>
              </tr>
            </tbody>
          </table>
          {customerGet}
          <table className="echron-head">
            <tbody>
              <tr>
                <th align="left"><i className="fa fa-pencil"></i> Add Magerment</th>
              </tr>
            </tbody>
          </table>

          <div className='echron-content'>
            <div className='tab'>

              <ul>
                <li className={isActive === 'tab-1' ? 'active' : ''} onClick={() => evIsActive('tab-1')}>General</li>
                <li className={isActive === 'tab-2' ? 'active' : ''} onClick={() => evIsActive('tab-2')}>Style</li>
              </ul>

              <div className={isActive === 'tab-1' ? 'form active' : 'form'}>
                <div className="group">
                  <label><span><sup>*</sup>Clothe</span></label>
                  <select className="input" name="m_follow" id="">
                    {/* <option value="1">Select</option> */}
                    <option value="1">Shirt</option>
                    {/* <option value="2">Pant</option>
                    <option value="3">Suit</option>
                    <option value="3">Coat</option>
                    <option value="3">Blazer</option>
                    <option value="3">Kurta</option>
                    <option value="3">Pajama</option>
                    <option value="3">Bandi</option>
                    <option value="3">Prince Suit</option> */}
                  </select>
                </div>
                <div className="group">
                  <label><span>Length</span></label>
                  <input className="input" type="text" placeholder="Length" />
                </div>
                <div className="group">
                  <label><span>Shoulder</span></label>
                  <input className="input" type="text" placeholder="Sholder" />
                </div>
                <div className="group">
                  <label><span>Neck Size</span></label>
                  <input className="input" type="text" placeholder="Neck Size" />
                </div>
                <div className="group">
                  <label><span>Pocket Size</span></label>
                  <input className="input" type="text" placeholder="Pocket Size" />
                </div>
                <div className="group">
                  <label><span>Inside Pocket Size</span></label>
                  <input className="input" type="text" placeholder="Inside Pocket Size" />
                </div>
                <div className="group">
                  <label><span>Cuf Size & Style</span></label>
                  <input className="input" type="text" placeholder="Cuf Size & Style" />
                </div>
                <div className="group">
                  <label><span>Coller Size</span></label>
                  <input className="input" type="text" placeholder="Cuf Size & Style" />
                </div>
                <div className="group">
                  <label><span>Remark</span></label>
                  <textarea className="input" cols="40" rows="10"></textarea>
                </div>
              </div>

              <div className={isActive === 'tab-2' ? 'form active' : 'form'}>
                <div className="group">
                  <label><span>Type</span></label>
                  <select className="input" name="type">
                    <option value="0">Shirt</option>
                    <option value="1">Woolen Shirt</option>
                    <option value="2">T-shirt</option>
                    <option value="3">Short Kurta</option>
                  </select>
                </div>
                <div className="group">
                  <label><span>Fitting and Comfort</span></label>
                  <select className="input" name="fit-comf">
                    <option value="0">Please Select</option>
                    <option value="1">Fitting</option>
                    <option value="2">Comfort</option>
                    <option value="3">Loose Arrow Armed</option>
                    <option value="4">Skin Fitting</option>
                    <option value="5">Others</option>
                  </select>
                </div>
                <div className="group">
                  <label><span>Inside Pocket</span></label>
                  <input className="input" type="text" placeholder="Inside Pocket" />
                </div>
                <div className="group">
                  <label><span>Round Daman</span></label>
                  <input className="input" type="text" placeholder="Round Daman" />
                </div>
                <div className="group">
                  <label><span>Collar Type</span></label>
                  <input className="input" type="text" placeholder="Collar Type" />
                </div>
                <div className="group">
                  <label><span>Front Pocket</span></label>
                  <select className="input" name="pkt-colr">
                    <option value="0">Please Select</option>
                    <option value="1">With Flap</option>
                    <option value="2">Without Flap</option>  
                  </select>
                </div>
                <div className="group">
                  <label><span>Sleeves</span></label>
                  <select className="input" name="slev">
                    <option value="0">Please Select</option>
                    <option value="1">Full Sleev</option>
                    <option value="2">Cut Sleev</option>  
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default OrderAdd