//common index component
import { Outlet } from 'react-router-dom'
import Header from '../component/common/Header'
import Navbar from '../component/common/Navbar'
function Echrontech() {
  return (
    <>
      <Header />
      <Navbar />
      <Outlet />
      {/* <Footer /> */}
    </>
  )
}
export default Echrontech