import {NavLink} from 'react-router-dom'
import "../../assets/css/Home.css"
import Breadcrumb from "../../component/common/Breadcrumb"
//component
function Home() {
  const breadBtn = <>
    <button className="echron-btn-icon">
      <i className="fa fa-cog"></i>
    </button>
  </>
  return (
    <>
      <section className="echron-main echron-home">
        <Breadcrumb page="Dashboard" btn={breadBtn} />
        <div className="echron-dashboard">
          <div className="container-fluid">
            <ul>
              <li>
                <h2>
                  <span>VIP Customer</span>
                  <span>20%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-people-group"></i>
                  <h3>24</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/customer">
                    View more...
                  </NavLink>
                </div>
              </li>
              <li>
                <h2>
                  <span>Prime Customer</span>
                  <span>30%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-people-group"></i>
                  <h3>65</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/customer">
                    View more...
                  </NavLink>
                </div>
              </li>
              <li>
                <h2>
                  <span>Normal Customer</span>
                  <span>30%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-people-group"></i>
                  <h3>41</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/customer">
                    View more...
                  </NavLink>
                </div>
              </li>
              <li>
                <h2>
                  <span>Total Customer</span>
                  <span>30%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-people-group"></i>
                  <h3>415</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/customer">
                    View more...
                  </NavLink>
                </div>
              </li>
            </ul>

            <ul>
              <li>
                <h2>
                  <span>New Order</span>
                  <span>10%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-arrow-down-up-across-line"></i>
                  <h3>14</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/order">
                    View more...
                  </NavLink>
                </div>
              </li>
              <li>
                <h2>
                  <span>Working Order</span>
                  <span>16%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-arrow-down-up-across-line"></i>
                  <h3>47</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/order">
                    View more...
                  </NavLink>
                </div>
              </li>
              <li>
                <h2>
                  <span>Completed Order</span>
                  <span>12%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-arrow-down-up-across-line"></i>
                  <h3>32</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/order">
                    View more...
                  </NavLink>
                </div>
              </li>
              <li>
                <h2>
                  <span>Deliverd Order</span>
                  <span>19%</span>
                </h2>
                <div className="content">
                  <i className="fa-solid fa-arrow-down-up-across-line"></i>
                  <h3>6984</h3>
                </div>
                <div className="more">
                  <NavLink to="/adm/order">
                    View more...
                  </NavLink>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
