import '../../assets/css/Login.css'
import mainLogo from'../../assets/img/logo.png'
function Login() {
  return (
    <>
      <section className="echron-login">
        <div className="content">
          
          <form>
            <h4><i className="fa fa-user"></i> Please enter your login details.</h4>
            <figure><img className="logo" src={mainLogo} alt="mainLogo" /></figure>
            <ul>
              <li>
                <label htmlFor="">Username</label>
                <input type="text" placeholder="Username" name="user" />
              </li>
              <li>
                <label htmlFor="">Password</label>
                <input type="password" placeholder="Password" name="password" />
                <a href="#">Forgotten Password</a>
              </li>
            </ul>
            <div className="echronButton">
              <a href="./"><button type="button"><i className="fa fa-key"></i> Login </button></a>
            </div>
          </form>
        </div>
        <div className="copyrights">
          Design & Develope by <a href="https://echrontech.com" target='_blank'>Echrontech</a> © 
          <small> 2009-2024 All Rights Reserved.</small>
        </div>
      </section>
    </>
  )
}
export default Login