import {NavLink} from 'react-router-dom'
import { useState } from "react";
import '../../assets/css/CustomerAdd.css'
import Breadcrumb from "../../component/common/Breadcrumb"
function OrderAdd() {
  const breadBtn = <>
    <button className="echron-btn-icon">
      <NavLink to="/adm/customer" title='Save'><i className="fa-solid fa-save"></i></NavLink>
    </button>
  </>

  const [isActive, setIsActive] = useState('tab-1')
  const evIsActive = (tab) => {
    setIsActive(tab)
  }

  return (
    <>
      <section className="echron-main echron-addedit">
        <Breadcrumb page="Order Add" btn={breadBtn} />
        <div className='container-fluid'>
          <table className="echron-head">
            <tbody>
              <tr>
                <th align="left"><i className="fa fa-pencil"></i> Add Order</th>
              </tr>
            </tbody>
          </table>
          <div className='echron-content'>
            <div className='tab'>
              <ul>
                <li className={isActive === 'tab-1' ? 'active' : ''} onClick={() => evIsActive('tab-1')}>General</li>
                <li className={isActive === 'tab-2' ? 'active' : ''} onClick={() => evIsActive('tab-2')}>Data</li>
              </ul>

              <div className={isActive === 'tab-1' ? 'form active' : 'form'}>
                <div className="group">
                  <label for=""><span><sup>*</sup>Customer</span></label>
                  <select className="input" name="customer">
                    <option value="1">Mehfooz  9998887776</option>
                    <option value="2">Hari Krishna  9998887775</option>
                    <option value="3">Abhishek  9998887774</option>
                    <option value="4">Wasid  9998887773</option>
                    <option value="5">Saleem  9998887772</option>
                    <option value="6">Amit  9998887771</option>
                  </select>
                </div>
                <div className="group">
                  <label for=""><span><sup>*</sup>Clothe</span></label>
                  <select className="input" name="m_follow" onChange="echronUnload()" id="">
                    {/* <option value="1">Select</option> */}
                    <option value="1">Shirt</option>
                    {/* <option value="2">Pant</option>
                    <option value="3">Suit</option>
                    <option value="3">Coat</option>
                    <option value="3">Blazer</option>
                    <option value="3">Kurta</option>
                    <option value="3">Pajama</option>
                    <option value="3">Bandi</option>
                    <option value="3">Prince Suit</option> */}
                  </select>
                </div>
                <div className="group">
                  <label for=""><span>Magerment</span></label>
                  <select className="input" name="m_follow" onChange="echronUnload()" id="">
                    <option value="">Select</option>
                    <option value="1">31 June 2024 (1)</option>
                    <option value="2">18 Oct 2023 (2)</option>
                    <option value="3">25 Jan 2022 (3)</option>
                  </select>
                </div>
                <div className="group">
                  <label for=""><span>Status</span></label>
                  <select className="input" name="m_follow" onChange="echronUnload()" id="">
                    <option value="1">New</option>
                    <option value="2">Working</option>
                    <option value="3">Completed</option>
                    <option value="4">Deliverd</option>
                  </select>
                </div>
              </div>

              <div className={isActive === 'tab-2' ? 'form active' : 'form'}>
                <div className="group">
                  <label for=""><span>Delivery Date</span></label>
                  <input className="input" type="text" placeholder="Delivery Date" />
                </div>
                <div className="group">
                  <label for=""><span>Clothe Size</span></label>
                  <input className="input" type="text" placeholder="Clothe Size" />
                </div>
                
                <div className="group">
                  <label for=""><span>Number of Piece</span></label>
                  <select className="input" name="n_piece" onChange="echronUnload()" id="">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default OrderAdd