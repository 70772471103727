import { createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'; // Importing useSelector
import { usePostCustsMutation, usePutCustsMutation } from './clientapi';
import { useNavigate } from "react-router-dom";

/* function generateToken(n=100) {
  var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var token = '';
  for(var i = 0; i < n; i++) {
    token += chars[Math.floor(Math.random() * chars.length)];
  }
  return token;
} */

// Define your custom React Hook for submitting the form
export const useSubmitForm = (id) => {
  const [enqPost] = usePostCustsMutation();
  const [enqPut] = usePutCustsMutation();
  const formData = useSelector(selectForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitForm = async () => {
    
    try {
      // Call the post API
      dispatch(setFormResp({load: true}))
      const echronForm = new FormData();

      // Append each field of formData to echronForm
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          echronForm.append(key, formData[key])
        }
      }
      
      const response = id? await enqPut({body: echronForm, id: id}) : await enqPost(echronForm);
      if(response.error) {
        const messageShow = response.error.data.message
        dispatch(setFormResp({error: true, success: false, messageShow: messageShow}))
        //console.log('error syed', response.error)
      }
      else {
        const messageShow = response.data.message
        dispatch(setFormResp({success: true, error: false, messageShow: messageShow}))
        
        //navigate('/adm/customer?type=all');
        window.location.href = '/adm/customer?type=all';
      }
    }
    catch (error) {
      // Handle error
      console.error('Error submitting form:', error);
    }
    finally {
      dispatch(setFormResp({load: false}))
    }
  };
  return submitForm;
};


const formSlice = createSlice({
  name: 'form',
  initialState: {
    formFields: {name: '', email: '', phone: '', phone_alt: '', type: '', address: '', picture: ''},
    formResps: {load: false, error: false, messageShow: [], success: false,},
    btnFields: {status: ''},
  },
  reducers: {
    echronEvInput: (state, action) => {
      return {
        ...state,
        formFields: {
          ...state.formFields,
          [action.payload.type]: action.payload.value,
        },
      };
    },
    echronEvBtn: (state, action) => {
      return {
        ...state,
        btnFields: {
          ...state.btnFields,
          [action.payload.type]: action.payload.value,
        },
      };
    },
    setForm: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setFormResp: (state, action) => {
      state.formResps = { ...state.formResps, ...action.payload };
    },
    setBtnResp: (state, action) => {
      state.btnFields = { ...state.btnFields, ...action.payload };
    },
  },
});

export const { setForm, setBtnResp, echronEvInput, echronEvBtn, setFormResp } = formSlice.actions;
export const selectForm = (state) => state.form.formFields;
export const selectBtn = (state) => state.form.btnFields;
export const selectFormResp = (state) => state.form.formResps;

export default formSlice.reducer;