import { useGetCustsQuery } from "../echron/clientapi"
function HomeMain() {
  const custsRes = useGetCustsQuery()

  if(custsRes.isLoading) return <div>Loading...</div>
	if(custsRes.isError) return <h1>An Error occured {custsRes.error.error}</h1>
	if(custsRes.isSuccess) {
    return (
      <>
        <section>
          <h1>De Nova Tailors App</h1>
          <ul>
            {
              custsRes.data.result.map((post) =>
                <li key={post.id}>
                  Id: {post.id}
                </li>
              )
            }
          </ul>
        </section>
      </>
    )
  }
}
export default HomeMain