import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://echron.denovatailors.com/'
    //baseUrl: 'https://echron.denovatailors.com/saleem/'
    //baseUrl: 'http://127.0.0.1:8000/api/'
  }),
  endpoints: (builder)=>({
    getCusts: builder.query({
      query: (type) => {
        let echronUrl
        switch(type) {
          case 'vip' :
            echronUrl = 'customer/type_3'
          break;
          case 'prime' :
            echronUrl = 'customer/type_2'
          break;
          case 'normal' :
            echronUrl = 'customer/type_1'
          break;
          default :
            echronUrl = 'customer'
          break;
        }
        return {
          url: echronUrl,
          method: 'GET'
        };
      }
    }),
    getIdCusts: builder.query({
      query: (id) => ({
        url: 'customer/id_'+id,
        method: 'GET'
      })
    }),
    postCusts: builder.mutation({
      query: (echronFields) => {
        return {
          url: 'customer',
          method: 'POST',
          body: echronFields
        };
      }
    }),
    putCusts: builder.mutation({
      query: (echronFields) => {
        return {
          url: 'customer/id_'+echronFields.id,
          method: 'PUT',
          body: echronFields.body
        };
      }
    }),
    putStatusCusts: builder.mutation({
      query: (echronFields) => {
        return {
          url: 'customer/status/id_'+echronFields.id,
          method: 'PUT',
          body: echronFields.body
        };
      }
    }),
  })
})

export const { useGetCustsQuery, useGetIdCustsQuery, usePostCustsMutation, usePutCustsMutation, usePutStatusCustsMutation } = clientApi