import {NavLink} from 'react-router-dom'
import mainLogo from'../../assets/img/logo.png'
import '../../assets/css/common/Header.css'
function Header() {
  return (
    <header>
      <div className="container-fluid">
        <div className="row">
          <div className="head-left col-2">
            <img className="logo-1" src={mainLogo} alt="mainLogo" />
          </div>
          <div className="head-right col-10">
            <ul className="row">
              <li>
                <a className="navi" href="#">
                  <figure>
                    <img src="https://www.echron.apartmentsingurugram.com/assets/img/student.png" alt="" />
                  </figure>
                  Aftab
                </a>
              </li>
              <li>
              <NavLink to="/adm/login" className="navi">
                <span className="log-out">
                  <i className="fa fa-sign-out"></i> Logout
                </span>
              </NavLink>
             </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
